import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import { BrowserTracing, handleErrorWithSentry } from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://b586e9bda08363591b90a9b7a1be152a@o4504696500846592.ingest.sentry.io/4505767581450240',
  tracesSampleRate: dev ? 0 : 1.0,
  integrations: [
    new BrowserTracing({ traceFetch: false })
  ]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
